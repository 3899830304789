#page-3 {
    color: white;
    font-family: 'Inter', 'JetBrains Mono', monospace;
}

#page-3-title {
    margin: 20px;
    font-size: 27px;
    text-decoration: underline;
}

#page-3-note {
    margin: 20px;
}

#projects-div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    animation-name: display-page;
    animation-duration: 770ms;
    animation-fill-mode: forwards;
}

.project {
    margin: 20px;
    padding: 20px;

    background-color: #7777D9;

    border: solid;
    border-color: white;
    border-width: 1px;
}

.project-description-heading {
    text-decoration: none;
}