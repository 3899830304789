@import url('https://fonts.googleapis.com/css2?family=Inter&family=Orbitron:wght@800&display=swap');


#page-2 {
    margin: 0px;
    padding: 4%;
    font-family: 'Inter', 'JetBrains Mono', monospace;
}

#cv-container {
    animation-name: display-page;
    animation-duration: 770ms;
    animation-fill-mode: forwards;
}

#name {
    margin: 0px;
    
    color: #7777D9;

    font-size: 2vw;
    font-weight: 1000;
    text-decoration: underline;
}

#cv-links {
    margin-top: 2%;
    margin-bottom: 2%;

    color: grey;
    font-size: 1vw;
}

.cv-link { margin: 0px; }

.cv-text {
    color: white;
    font-size: 1.15vw;
}

#heading-skills {
    color: #7777D9;
}

.skills-list-item {
    padding-top: 1%;
    padding-bottom: 1%;
}