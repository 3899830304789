body {

    margin: 0px;
    padding: 0px;

    width: 100vw;
    height: 100vh;

    background-color: #151515;

}

#root {

    margin: 0px;
    padding: 0px;

    width: 100vw;
    height: 100vh;
}