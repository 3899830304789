@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');

body {
    overflow: hidden;
}

#main {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

#content-div {
    transition: width 0.77s cubic-bezier(0, .07, 0, 1);
}

.default-content {
    width: 50%;
    animation-name: default-content-appear;
    animation-duration: 700ms;
    animation-fill-mode: forwards;
}

.detailed-content {
    overflow-y: scroll;
    width: 77%;
}

::-webkit-scrollbar {
    width: 7px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #151515;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #FFFFFF;
    border-radius: 50px;
}

#side-bar {

    flex-grow: 1;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    background-color: #7777D9;

}

#sidebar-quote {

    width: 18vw;

    color: white;
    font-size: 1vw;
    font-family: 'JetBrains Mono', monospace;

    margin-bottom: 10vh;

    animation-name: sidebar-quote;
    animation-duration: 1540ms;
    animation-fill-mode: forwards;

}

#whiterock {

    margin: 0px;
    padding: 0px;

    width: 20vw;
    height: 14vw;

    color: white;

    font-size: 1vw;
    font-family: 'JetBrains Mono', monospace;
    
    background-image: url("./../../images/whiterock.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    animation-name: whiterock;
    animation-duration: 1540ms;
    animation-fill-mode: forwards;
}

#sidebar-buttons {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

#sidebar-links {

    margin-top: 2vh;
    margin-bottom: 2vh;

    display: flex;
    flex-direction: row;

    justify-content: space-between;

    background-color: #7777D9;

    width: 14vw;

    animation-name: sidebar-links;
    animation-duration: 770ms;
    animation-fill-mode: forwards;
}

.sidebar-icon {
    color: white;
    font-size: 1.7vw;
    
    margin: 0.5px;
    padding: .50vw;

    background-color: #151515;

    transition: color 0.15s linear, background-color 0.15s linear, transform 0.15s linear;
}

.sidebar-icon:hover {
    color: #151515;
    background-color: white;
    transform: scale(1.15);
}

.side-bar-button {

    width: 14vw;

    margin: 2.5%;
    padding: 5%;

    color: white;
    background-color: #151515;

    border: solid;
    border-color: #151515;

    font-size: 1vw;
    font-family: 'JetBrains Mono', monospace;

    transition: background-color 0.25s linear;

}

.default-buttons {
    animation: default-buttons-animation;
    animation-duration: 700ms;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, .07, 0, 1);
}

.detailed-buttons {
    animation: detailed-buttons-animation;
    animation-duration: 700ms;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, .07, 0, 1);
}

.side-bar-button:hover {
    color: #151515;
    background-color: #FFFFFF00;
}

#eden {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    color: white;
    background-color:#7777D9;

    font-size: 2vw;
    font-family: 'JetBrains Mono', monospace;
}

#eden-main-content {
    margin: 10%;
    font-size: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#eden-content {
    text-align: center;
    width: 70vw;
}

.eden-button {
    margin: 1vw;
    padding: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;

    font-size: 2vw;
    font-family: 'JetBrains Mono', monospace;

    border: none;
    color: #7777D9;
    background-color: white;

}

.eden-button:hover {
    color: white;
    background-color: #151515;
}

#eden-image {
    max-height: 70vh;
    max-width: 90vw;

    height: auto;
    width: auto;

    border: solid;
    border-radius: 50px;
    border-width: 5px;
    border-color: white;
}

@keyframes default-content-appear {
    0% {
        padding-top: 50%;
        opacity: 0;
    }
    100% {
        padding-top: 0%;
        opacity: 1;
    }
}

@keyframes sidebar-quote {
    0% {
        opacity: 0;
    }
    66.6% {
        opacity: 0;
        transform: translate(0vh, -10vh);
    }
    100% {
        opacity: 1;
        transform: translate(0vh, 0vh);
    }
}

@keyframes whiterock {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translate(0vh, -10vh);
    }
    100% {
        opacity: 1;
        transform: translate(0vh, 0vh);
    }
}

@keyframes sidebar-links {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translate(0vw, -10vh);
    }
    100% {
        opacity: 1;
        transform: translate(0vh, 0vh);
    }
}

@keyframes default-buttons-animation {
    0% {
        transform: translate(0vw, 27vh);
    }
    100% {
        transform: translate(0vw, 0vh);
    }
}

@keyframes detailed-buttons-animation {
    0% {
        transform: translate(0vw, -20vh);
    }
    100% {
        transform: translate(0vw, 0vh);
    }
}

@keyframes display-page {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}