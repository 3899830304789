@media only screen and (orientation : portrait) {
    .default-content {
        width: 90%;
        animation-name: default-content-appear;
        animation-duration: 700ms;
        animation-fill-mode: forwards;
    }

    .detailed-content {
        overflow-y: scroll;
        width: 90%;
    }
    
    #side-bar {
        flex-grow: 1;

        display: flex;
        flex-direction: column;

        justify-content: flex-start;
        align-items: center;

        margin: 0px;
        padding: 0px;

        background-color: #7777D9;
    }

    #sidebar-buttons {
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }

    .side-bar-button {

        width: 10vw;
        height: 10vw;

        margin: 0px;
        margin-bottom: 0.25vh;
    
        color: white;
        background-color: #7777D9;
    
        border: none;
    
        font-size: 5vw;
        font-family: 'JetBrains Mono', monospace;
    
        transition: background-color 0.25s linear;
    }

    #picture {
        width: 40vw;
        height: 40vw;
    }

    #information {
        font-size: 4vw;
    }

    #icons {
        margin-top: 5vh;
        margin-bottom: 5vh;

        font-size: 3.4vh;
    }

    .icon {
        margin: 1.7vh;
    }

    #logo { display: none; }


    #name { font-size: 5vw; }
    #cv-links { font-size: 2.5vw; }
    .cv-text { font-size: 4vw; }

    #page-3-title { font-size: 5vw; }
    #page-3-note { font-size: 4vw; }

    #projects-div { font-size: 4vw; }
    .link { font-size: 2.5vw; }
}