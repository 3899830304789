@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');

#page-1 {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

}

#picture {
    width: 200px;
    height: 200px;

    min-width: 200px;
    min-height: 200px;

    border: solid;
    border-width: 7px;
    border-color: #333333;
    border-radius: 50%;

    background-image: url("./../../images/picture11.jpg");
    background-size:cover;

    transition: box-shadow 0.25s linear, border-color 0.25s linear;
}

#picture:hover {
    box-shadow: 0px 0px 25px 1px #7777D9;
    border-color: #7777D9;
}


#information {
    color: white;
    font-size: 17px;
    font-family: 'JetBrains Mono', monospace;
    white-space: pre-line;
}

#icons {
    display: flex;
    flex-direction: row;
    
    margin-top: 15px;
    margin-bottom: 15px;

    font-size: 18px;
    font-weight: 1000;
    color: grey;
}

.icon {
    margin: 10px;
}

.icon:hover {
    color: #7777D9;
}

#sidebar-info {
    margin: 0px;
}

#logo {
    position: absolute;

    margin-left: 50%;

    display: flex;
    flex-direction: row;

    font-family: 'Times New Roman', Times, serif;
    font-size: 15vw;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    animation-name: logo-appear;
    animation-duration: 1200ms;
    animation-fill-mode: forwards;
}

@keyframes logo-appear {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#logo-m {
    margin-left: 7.7%;
    color: #7777D9;
}

#logo-g {
    margin-left: -15.7%;
    color: #151515;
}